import React from 'react';
import ReactDOM from 'react-dom';
import {MastersDashboardApp} from './MastersDashboardApp';
import reportWebVitals from '../reportWebVitals';
import '../i18n';
import '../importWebFonts';
import { dataLayer } from "../utils/dataLayer";

const isProduction = process.env.NODE_ENV === 'production';
dataLayer.init(isProduction);

ReactDOM.render(
  <MastersDashboardApp/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
