import { Button, Card, Link, Typography } from "@material-ui/core";
import * as Yup from 'yup';
import { SchemaOf } from "yup";
import { makeValidate, Checkboxes } from 'mui-rff';
import { Form, FormSpy } from "react-final-form";
import { useAuthStyles } from "./styles";
import { PhoneInput } from "./PhoneInput";
import React, { useCallback, useState } from "react";
import { useOnEnterKeyPress } from "../../../hooks/useOnEnterKeyPress";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg'

type PhoneEnterCardProps = {
  onPhoneEnter: (phone: string) => void
};

export const PhoneEnterCard = ({ onPhoneEnter }: PhoneEnterCardProps) => {
  const classes = useAuthStyles();
  const [phone, phoneSet] = useState('');

  const { t } = useTranslation();

  const schema: SchemaOf<any> = Yup.object().shape({
    terms: Yup.boolean().oneOf([true], 'Необходимо согласие').required('Необходимо согласие'),
  })

  const validate = makeValidate(schema);

  const onPhoneChange = useCallback((phone: string) => {
    const phoneRight = phone.split('').filter(i => parseInt(i) >= 0).join('');
    phoneSet(phoneRight);
  }, [phoneSet]);

  const okButtonDisabled = phone.length !== 9;

  const handleFormSubmit = () => {
    if (!okButtonDisabled) {
      onPhoneEnter(phone);
    }
  };

  const onEnterPress = useOnEnterKeyPress(() => {
    if (!okButtonDisabled) {
      handleFormSubmit();
      (window as any).dataLayer.push({
        event: 'registration_buttonsendcode_entered',
      });
    }
  }, [okButtonDisabled, handleFormSubmit]);

  const onSubmit = (values: string) => {
    handleFormSubmit();
    (window as any).dataLayer.push({
      event: 'registration_buttonsendcode_entered',
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.container}>
            <Card classes={{ root: classes.card }} style={{ overflow: 'inherit' }}>
              <Typography variant="h1">{t('enter-phone')}</Typography>
              <Typography>{t('we-send-sms')}</Typography>
              <PhoneInput onPhoneChange={onPhoneChange} />
              <Button variant={"contained"}
                disabled={okButtonDisabled}
                style={{marginBottom: '15px'}}
                onClick={handleSubmit}
                onKeyDown={onEnterPress}
                >
                {t('send-code')}
              </Button>
              <Checkboxes
                name="terms"
                style={{marginTop: '-15px'}}
                required
                data={{
                  label: (
                    <span>
                      Я ознакомился и принимаю условия{" "}
                      <Link href="https://b2c.by/terms">{t('accept-terms')}</Link>
                        {` ${t('and')} `}
                        <Link href="https://b2c.by/privacy">{t('accept-privacy')}</Link>.
                    </span>
                  ),
                  value: true,
                }}
              />
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  if (values.terms) {
                    (window as any).dataLayer.push({
                      event: 'registration_checkbox_completed',
                    });
                  }
                  return null;
                }}
              </FormSpy>
              <div style={{
                position: 'absolute',
                bottom: -50,
                left: '50%',
                transform: 'translateX(-50%)'
              }}>
                <LogoIcon />
              </div>
            </Card>
          </div>
        </form>
      )}
    />
  );
}