declare global {
    interface Window {
        dataLayer: {
            push: (event: Record<string, string | number>) => void
        } | Record<string, string | number>[]
    }
}

export const dataLayer = {
    keepOnlyLastEvents: 10,
    initReal: function () {
        // Код Google Tag Manager
        const script = document.createElement('script');
        script.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;
                
                const _ge = new CustomEvent('gtm_loaded', { bubbles: true });d.dispatchEvent(_ge);
                
                f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MC9LHQZ4');
              `;


        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC9LHQZ4"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.prepend(noscript);
    },
    initFake: function () {
        console.info('Fake DataLayer init for test mode')
        window.dataLayer = {
            push: (event: Record<string, string | number>) => {
                console.info('DataLayer push event', event)
            }
        }
        const _ge = new CustomEvent('gtm_loaded', { bubbles: true });
        window.dispatchEvent(_ge);
    },
    init: function (isProduction: boolean) {
        if (isProduction) {
            this.initReal()
        } else {
            this.initFake()
        }
    },
    lastSentEvents: [] as string[],
    push: function (event: Record<string, string | number>) {

        const eventHash = JSON.stringify(event)
        if (this.lastSentEvents.includes(eventHash)) {
            // Prevent from sending the same event multiple times
            console.warn('Event already sent', event)
            return
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event)

        // keep only last X events
        this.lastSentEvents = [eventHash, ...this.lastSentEvents].slice(0, this.keepOnlyLastEvents)
    }
}