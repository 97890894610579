import {Link, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const TIME_WAITING = 59;

interface SendCodeTimeoutProps {
  timeStartMilliseconds: number,
  onResendCodeClick: () => void,
}

export const SendCodeTimeout = (props: SendCodeTimeoutProps) => {
  const {timeStartMilliseconds, onResendCodeClick} = props;
  const [timeLeft, timeLeftSet] = useState(TIME_WAITING);
  const {t} = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = +new Date();
      timeLeftSet(
        Math.ceil(TIME_WAITING - (currentTime - timeStartMilliseconds) / 1000)
      );
    }, 500);
    return () => clearInterval(interval);
  }, [timeStartMilliseconds]);

  const handleResendSms = () => {
    onResendCodeClick();
    (window as any).dataLayer.push({
      event: 'registration_buttonresendcode_entered',
    });
  }

  return (
    <div>
      {timeLeft >= 0
        ? <Typography>{t('send-otp-again')} (0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft})</Typography>
        : <Link onClick={handleResendSms} href="#">{t('send-otp-again')}</Link>}
    </div>
  );
}
