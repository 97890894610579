import { Middleware } from '@reduxjs/toolkit';
import { dataLayer } from "../../utils/dataLayer";
import {RootState} from "./index";

const gtmMiddleware: Middleware<unknown, RootState> =
    _storeAPI => next => action => {

        // Check if the action is the one you want to track
        if (action.type === 'auth/fetchUserProfile/fulfilled') {
            // Push the event to the data layer
            dataLayer.push({
                user_id: action.payload.id
            });
        }

        // Pass the action to the next middleware in the chain
        return next(action);
    }
export default gtmMiddleware;
